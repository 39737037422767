@import "../../css/variables.scss";

#rainbow {
	z-index: 99;
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 14px;
	min-width: 0.75vw;

	.rainbow-block {
		width: 50%;
		height: 100%;

		@for $i from 1 through length($rainbowColors) {
			$rainbowColor: nth($rainbowColors, $i);

			&.rainbow-#{$i - 1} {
				background-color: $rainbowColor;
			}
		}
	}
}

// global
@for $i from 1 through length($rainbowColors) {
	$rainbowColor: nth($rainbowColors, $i);

	.rainbow-bg,
	.rainbow-color,
	.rainbow-color-hover,
	.rainbow-color-hover:hover,
	.rainbow-fill,
	.rainbow-fill svg,
	.rainbow-fill svg path,
	.rainbow-fill-hover,
	.rainbow-fill-hover svg,
	.rainbow-fill-hover svg path,
	.rainbow-fill-hover:hover,
	.rainbow-fill-hover:hover svg,
	.rainbow-fill-hover:hover svg path,
	.rainbow-stroke,
	.rainbow-stroke svg,
	.rainbow-stroke svg path,
	.rainbow-stroke-hover,
	.rainbow-stroke-hover svg,
	.rainbow-stroke-hover svg path,
	.rainbow-stroke-hover:hover,
	.rainbow-stroke-hover:hover svg,
	.rainbow-stroke-hover:hover svg path,
	.rainbow-fill-active,
	.rainbow-fill-on-hover,
	.rainbow-fill-on-hover:hover {
		transition: all linear 0.4s;
	}

	.rainbow-color-#{$i - 1} { /* <body> */
		transition: all linear 0.4s;

		::-moz-selection {
			color: #fff;
			background: $rainbowColor;
		}
		
		::selection {
			color: #fff;
			background: $rainbowColor;
		}

		.rainbow-bg {
			background-color: $rainbowColor;
		}

		.rainbow-color {
			color: $rainbowColor;
		}

		.rainbow-color-hover:hover {
			color: $rainbowColor;
		}

		.rainbow-fill {
			fill: $rainbowColor !important;
			svg {
				fill: $rainbowColor !important;
				path {
					fill: $rainbowColor !important;
				}
			}
		}

		.rainbow-fill-hover:hover {
			fill: $rainbowColor !important;
			svg {
				fill: $rainbowColor !important;
				path {
					fill: $rainbowColor !important;
				}
			}
		}

		.rainbow-stroke {
			stroke: $rainbowColor !important;
			svg {
				stroke: $rainbowColor !important;
				path {
					stroke: $rainbowColor !important;
				}
			}
		}

		.rainbow-stroke-hover:hover {
			stroke: $rainbowColor !important;
			svg {
				stroke: $rainbowColor !important;
				path {
					stroke: $rainbowColor !important;
				}
			}
		}

		.rainbow-fill-active {
			background-color: $rainbowColor;
			img {
				opacity: 0;
			}
		}

		.rainbow-fill-on-hover {
			&:hover {
				background-color: $rainbowColor;
			}

			img {
				opacity: 0;
			}
		}
	}
}