@import "../../complements/font-size.scss";
@import "../../css/mixins-and-functions.scss";
@import "../../css/variables.scss";

.TerminalWindow {
	display: none;
	$height: 250px;

	z-index: 4;
	position: absolute;
	right: 3%;
	top: calc(50% - ($height / 2));

	height: $height;
	width: 35%;

	opacity: 0.8;
	transition: all linear 0.4s;

	&:hover {
		opacity: 1;
		transition: all linear 0.4s;
	}

	// slpMd
	@media screen and (min-width: map-get($breakpoints, slpMd)) {
		width: 45%;
	}

	.terminal-window-inner {
		overflow: hidden;
		height: 100%;
		width: 100%;
		background-color: #000;
		border-radius: 20px;

		.terminal-header {
			padding: 0 20px;
			width: 100%;
			height: 50px;
			background-color: #181818;

			.tabs-container {
				height: inherit;

				.tab {
					cursor: pointer;
					height: inherit;
					padding: 0 15px;

					-webkit-user-select: none;
					user-select: none;

					p {
						@include fontSize(1rem, 1rem, 1rem, 1rem, 1.05rem, 1.1rem, 1.15rem);
						margin-bottom: 0;
						// font
						color: #fff;
						white-space: nowrap;

						i {
							position: relative;
							top: 2px;
							padding-right: 5px;
						}
					}
				}
			}
		}

		pre.terminal-code {
			overflow-y: scroll;
			overflow-y: scroll;
			height: 100%;
			display: none;
			padding: 15px;

			font-family: "Fira Code", monospace !important;
			color: #fff;

			&.active {
				display: block;
			}
		}
	}

	/* scrollbar webkit */
	pre.terminal-code::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		border-radius: 6px;
	}

	/* /scrollbar webkit */

	/* scrollbar gecko */
	pre.terminal-code {
		border-radius: 8px;
		scrollbar-width: thin;
		/* 8px */
	}

	/* /scrollbar gecko */
}




// rainbow
@include forEach($rainbowColors) {
	// $length, $index, $value
	$rainbowColor: nth($rainbowColors, $index);

	.rainbow-color-#{$index - 1} .TerminalWindow {
		.terminal-window-inner {
			.terminal-header {
				.tabs-container {
					.tab {
						border-bottom: 3px solid darken($rainbowColor, 35%);
						transition: all linear 0.4s;

						&.active {
							background-color: rgba($rainbowColor, 0.15);
							border-bottom: 3px solid $rainbowColor;
							transition: all linear 0.4s;
						}
					}
				}
			}
		}

		pre.terminal-code::-webkit-scrollbar {
			background-color: darken($rainbowColor, 35%);
		}

		pre.terminal-code::-webkit-scrollbar-thumb {
			background-color: $rainbowColor;
		}

		pre.terminal-code::-webkit-scrollbar-thumb {
			background-color: $rainbowColor;
		}

		pre.terminal-code {
			scrollbar-color: rgba($rainbowColor, 0.75) rgba($rainbowColor, 35%);
		}
	}
}