@import "../../css/variables.scss";

#about-me-menu {
	position: absolute;
	right: 0;
	top: 0;

	.block-icons {
		margin-top: 10vh;
		padding: 15px 16px;
		width: calc(40px + 32px); // 40px = icon size, 32px = padding * 2
		background-color: #ffffff;
		border-radius: 99px;
		box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);

		.menu-icon {
			cursor: pointer;
			height: 40px;
			width: 40px;
			margin-top: 1rem;
			margin-bottom: 1rem;

			-webkit-mask-size: 100% !important;
			mask-size: 100% !important;
			user-select: none;
		}
	}
}

@for $i from 1 through length($rainbowColors) {
	$rainbowColor: nth($rainbowColors, $i);

	.rainbow-color-#{$i - 1} {
		#about-me-menu .menu-icon {
			transition: all linear 0.4s;
			&:hover {
				color: $rainbowColor;
			}
		}
	}
}