@import "../../complements/font-size.scss";
@import "../../css/mixins-and-functions.scss";
@import "../../css/variables.scss";

$border: 7px;
$borderMobile: 5px;

.btn {
	overflow: hidden;
	position: relative;
	background-clip: padding-box;
	padding: 0.55rem 1.5rem;
	border-radius: 99px;
	z-index: 1;

	// font
	letter-spacing: 2px;
	line-height: 20px;
	text-transform: uppercase;
	font-weight: 700;

	@media screen and (min-width: 1080px) {
		// slp
		font-weight: 400
	}

	&:before,
	&:after {
		border-radius: 99px;
	}

	// btn-white
	color: #fff;
	border: $borderMobile solid rgba(255, 255, 255, 0.25);

	@media screen and (min-width: 1080px) {
		// slp
		border: $border solid rgba(255, 255, 255, 0.55);
	}

	&.btn-dark {
		color: #fff;
		border: $borderMobile solid rgba(51, 51, 51, 0.25);

		@media screen and (min-width: 1080px) {
			// slp
			border: $border solid rgba(128, 128, 128, 0.35);
		}
	}

	&:active {
		top: 1px;
		left: 1px;
	}

	.btn-background-color {
		display: block;
		z-index: -1;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		filter: brightness(0.95) saturate(1.5);

		@media screen and (min-width: 1080px) {
			// slp
			filter: brightness(1) saturate(1);
		}
	}
}

@include forEach($rainbowOptions) {
	$incrementOfLight: get($item, incrementOfLight);
	$gradientColor1: get($item, gradientColor1);
	$gradientColor2: get($item, gradientColor2);
	$gradientPosition1: get($item, gradientPosition1);
	$gradientPosition2: get($item, gradientPosition2);
	$gradientColor1_hover: lighten($gradientColor1, $incrementOfLight);
	$gradientColor2_hover: lighten($gradientColor2, $incrementOfLight);

	.rainbow-color-#{$index - 1} {
		.btn {
			.btn-background-color {
				background-image: linear-gradient(to right,
						$gradientColor1 $gradientPosition1,
						$gradientColor2 $gradientPosition2);

				&.flip-h {
					background-image: linear-gradient(to right,
							$gradientColor2 $gradientPosition1,
							$gradientColor1 $gradientPosition2);
				}
			}

			&:hover {
				.btn-background-color {
					background-image: linear-gradient(to right,
							$gradientColor1_hover $gradientPosition1,
							$gradientColor2_hover $gradientPosition2);

					&.flip-h {
						background-image: linear-gradient(to right,
								$gradientColor2_hover $gradientPosition1,
								$gradientColor1_hover $gradientPosition2);
					}
				}
			}
		}
	}
}