@import "../../complements/font-size.scss";

#UnderConstructionMobile {
	z-index: 99;
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	transition: all linear 0.4s;

	h1,
	p {
		max-width: 90%;

		text-align: center;
		text-shadow:
			0 0 30px rgba(0, 0, 0, 0.75),
			0 0 30px rgba(0, 0, 0, 0.75),
			0 0 30px rgba(0, 0, 0, 0.75);
	}

	h1 {
		font-weight: 700;
	}

	p {
		@include fontSize(1.5rem, 1.55rem, 1.6rem);
		color: #fff;
	}

	.background-color {
		position: absolute;
		z-index: -1;
		opacity: 0.85;
		background-color: #000;

		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}
}