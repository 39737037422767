#code-writer-on-background {
	z-index: 2;
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	opacity: 0.7;
	overflow: hidden;
	user-select: none;
	line-height: 8px;

	.pre-code {
		position: absolute;
		opacity: 0;
		transition: all linear 0.4s;

		&.code-writer-show {
			opacity: 1;
			transition: all linear 0.4s;
		}

		.span-code {
			color: #fff;
			font-size: 8px;
		}
	}
}