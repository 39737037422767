#about-me-content {
	height: 100%;

	@media screen and (max-width: 1079px) { // <= mb
		height: initial;
		width: calc(100% - 25px);
		max-height: 50vh;
		margin-right: 15px;

		@media (orientation: landscape) {
			max-height: 80vh;
		}
	}
}