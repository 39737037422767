@import "../css/variables.scss";
@import "../complements/font-size.scss";

body {
	font-family: "#{$main-fontText}", sans-serif;
	color: #282828;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "#{$main-font}", sans-serif;
	color: #282828;
	margin-bottom: 0.5rem;
}

button {
	font-family: "#{$main-font}", sans-serif;
}

p {
	font-family: "#{$main-fontText}", sans-serif;
	color: #282828;
	text-align: justify;
	margin-bottom: 0.5rem;
}

h1 {
	@include fontSize(2.25rem, 2.5rem, 2.5rem, 2.75rem, 2.75rem, 3.25rem, 3.5rem, 3.75rem);
}

h2 {
	@include fontSize(2rem, 2.25rem, 2.25rem, 2.5rem, 2.5rem, 3rem, 3.25rem, 3.5rem);
}

h3 {
	@include fontSize(1.75rem, 2rem, 2rem, 2.25rem, 2.25rem, 2.75rem, 3rem, 3.25rem);
}

h4 {
	@include fontSize(1.5rem, 1.75rem, 1.75rem, 2rem, 2rem, 2.5rem, 2.75rem, 3rem);
}

h5 {
	@include fontSize(1.25rem, 1.5rem, 1.5rem, 1.75rem, 1.75rem, 2.25rem, 2.5rem, 2.75rem);
}

h6 {
	@include fontSize(1rem, 1.25rem, 1.25rem, 1.5rem, 1.5rem, 2rem, 2.25rem, 2.5rem);
}

p, input, textarea, select {
	@include fontSize(1rem, 1.1rem, 1.2rem, 1.2rem, 1.25rem, 1.35rem, 1.4rem, 1.5rem);
}

// @tippyjs lib
[data-tippy-root] .tippy-box {
	@include fontSize(0.8rem, 0.9rem, 1rem, 1rem, 1.05rem, 1.05rem, 1.2rem, 1.3rem);
}

strong {
	font-weight: 600;
}

button {
	@include fontSize(0.7rem, 0.8rem, 0.9rem, 0.9rem, 0.95rem, 1.05rem, 1.1rem, 1.2rem);
}

.global-title {
	line-height: 100%;
	margin-bottom: 0;
	font-weight: 700;
	text-transform: uppercase;
}