@import "../../complements/font-size.scss";

#home {
	h1 {
		@include fontSize(3rem, 3.35rem, 3.75rem, 4rem, 4rem, 4.5rem, 4.75rem, 5rem);
		font-weight: 700;
		text-transform: uppercase;
		line-height: initial;
		margin-bottom: 0;
	}
}

.scramble {
	.scramble-character {
		transition: all linear 0.4s;

		&.scramble-hidden {
			display: none;
		}
		&.scramble-scrambling {
			transition: all linear 0.4s;
			opacity: 0.3;
		}
		&.scramble-flash {
			animation: flash 0.2s linear;
		}
	}
}

@keyframes flash {
	0% {
		color: rgba(255, 255, 255, 0.9);
	}
	100% {
		color: currentColor;
	}
}