#screen-flag {
	display: none;
	border-top-right-radius: 10px;
	left: 0;
	padding: 5px 18px 5px 15px;
	position: fixed;
	bottom: 0;
	z-index: 999;

	span {
		color: #ffffff;
		position: relative;
		top: -1px;
	}
}

.localhost #screen-flag {
	display: block;
}