// tailwind-enhanced.scss v2
.cols-min-content {
    @for $i from 1 through 12 {
        &.grid-cols-#{$i} {
            grid-template-columns: repeat(#{$i}, min-content);
        }
    }
}

.rows-min-content {
    @for $i from 1 through 12 {
        &.grid-rows-#{$i} {
            grid-template-rows: repeat(#{$i}, min-content);
        }
    }
}