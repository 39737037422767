#social-contacts {
	$left: 14px;
	$width: 32px;

	position: absolute;
	z-index: 2;
	left: $left; // rainbow
	top: 0;
	height: 100vh;
	width: $width;

	@media (min-width: 576px) { // smb
		left: calc($left * 1.15);
		width: calc($width * 1.15);
	}
	@media (min-width: 1080px) { // slp
		left: calc($left * 1.175);
		width: calc($width * 1.175);
	}
	@media (min-width: 1367px) { // sdt
		left: calc($left * 1.2);
		width: calc($width * 1.2);
	}
	@media (min-width: 1681px) { // dt
		left: calc($left * 1.3);
		width: calc($width * 1.3);
	}
	@media (min-width: 1921px) { // ldt
		left: calc($left * 1.35);
		width: calc($width * 1.35);
	}

	.social-icon {
		$icon1Height: 20px;
		$icon2Height: 20px;
		$icon3Height: 17px;
		$icon4Height: 17px;
		$icon5Height: 16px;
		$icon6Height: 15px;

		.icon-email,
		.icon-smartphone,
		.icon-whatsapp,
		.icon-github,
		.icon-linkedin,
		.icon-linktree {
			margin: 20px auto;
		}

		.icon-email {height: $icon1Height}
		.icon-smartphone {height: $icon2Height}
		.icon-whatsapp {height: $icon3Height}
		.icon-github {height: $icon4Height}
		.icon-linkedin {height: $icon5Height}
		.icon-linktree {height: $icon6Height}

		@media (min-width: 576px) { // smb
			.icon-email {height: calc($icon1Height * 1.15)}
			.icon-smartphone {height: calc($icon2Height * 1.15)}
			.icon-whatsapp {height: calc($icon3Height * 1.15)}
			.icon-github {height: calc($icon4Height * 1.15)}
			.icon-linkedin {height: calc($icon5Height * 1.15)}
			.icon-linktree {height: calc($icon6Height * 1.15)}
		}
		@media (min-width: 1080px) { // slp
			.icon-email {height: calc($icon1Height * 1.175)}
			.icon-smartphone {height: calc($icon2Height * 1.175)}
			.icon-whatsapp {height: calc($icon3Height * 1.175)}
			.icon-github {height: calc($icon4Height * 1.175)}
			.icon-linkedin {height: calc($icon5Height * 1.175)}
			.icon-linktree {height: calc($icon6Height * 1.175)}
		}
		@media (min-width: 1367px) { // sdt
			.icon-email {height: calc($icon1Height * 1.2)}
			.icon-smartphone {height: calc($icon2Height * 1.2)}
			.icon-whatsapp {height: calc($icon3Height * 1.2)}
			.icon-github {height: calc($icon4Height * 1.2)}
			.icon-linkedin {height: calc($icon5Height * 1.2)}
			.icon-linktree {height: calc($icon6Height * 1.2)}
		}
		@media (min-width: 1681px) { // dt
			.icon-email {height: calc($icon1Height * 1.3)}
			.icon-smartphone {height: calc($icon2Height * 1.3)}
			.icon-whatsapp {height: calc($icon3Height * 1.3)}
			.icon-github {height: calc($icon4Height * 1.3)}
			.icon-linkedin {height: calc($icon5Height * 1.3)}
			.icon-linktree {height: calc($icon6Height * 1.3)}
		}
		@media (min-width: 1921px) { // ldt
			.icon-email {height: calc($icon1Height * 1.35)}
			.icon-smartphone {height: calc($icon2Height * 1.35)}
			.icon-whatsapp {height: calc($icon3Height * 1.35)}
			.icon-github {height: calc($icon4Height * 1.35)}
			.icon-linkedin {height: calc($icon5Height * 1.35)}
			.icon-linktree {height: calc($icon6Height * 1.35)}
		}
	}
}