#artificial-intelligence {
	z-index: 3;
	background-color: #fff;
	overflow: hidden;

	mix-blend-mode: darken;

	max-width: 100%;
	overflow: hidden;
	min-height: 100vh;

	margin-top: -40px;
	margin-bottom: -40px;

	@media screen and (min-width: 768px) {
		margin-top: -60px;
		margin-bottom: -60px;
	}

	& > div {
		min-height: 100vh;
		width: 100%;
	}

	.parallax {
		max-height: 100vh;
	
		.block-grid {
			position: relative;
			transform: rotate(-7deg);
			width: 80%;
	
			.img-blur {
				position: relative;
	
				img {
					border-radius: 15px;
					height: auto;
					max-width: 100%;
					overflow: hidden;
	
					&:nth-child(1) {
						filter: blur(2.5px) blur(5px) blur(10px) saturate(2) brightness(1);
					}
	
					&:nth-child(2) {
						opacity: 1;
						position: absolute;
						top: 0;
						left: 0;
					}
	
					&:hover {
						filter: saturate(1.2) contrast(1.05);
					}
				}
			}
		}
	}
}