@import "overlayscrollbars/overlayscrollbars.css";
@import "../css/variables.scss";

$os-size: 6px;

body > .os-scrollbar {
	z-index: 99;
}

@for $i from 1 through length($rainbowColors) {
	$rainbowColor: nth($rainbowColors, $i);

	.rainbow-color-#{$i - 1} {
		.os-scrollbar .os-scrollbar-track {
			&:hover, &.hover {
				background-color: rgba($rainbowColor, 0.25);

				.os-scrollbar-handle {
					background-color: $rainbowColor;
				}
			}

			.os-scrollbar-handle {
				background-color: rgba($rainbowColor, 0.5);
			}
		}
	}
}

.os-scrollbar {
	&.os-scrollbar-horizontal {
		height: $os-size;
		margin-bottom: 5px;
		padding: 0 5px;
		left: 14px !important;
		width: calc(100% - 14px);

		@media screen and (min-width: 1921px) {
			left: 0.75vw !important;
			width: calc(100% - 0.75vw);
		}

		.os-scrollbar-handle {
			height: $os-size;

			&:before {
				display: none;
			}
		}
	}

	&.os-scrollbar-vertical {
		margin-right: 5px;
		padding: 5px 0;
		width: $os-size;

		.os-scrollbar-handle {
			width: $os-size;

			&:before {
				display: none;
			}
		}
	}
}

.os-scrollbar,
.os-scrollbar-track {
	pointer-events: auto;
}

.os-scrollbar,
.os-scrollbar * {
	transition: all linear 0.4s !important;
}