.icon-animated {
	&.block-animate-svg-icon-draw-masking {
		.animate-svg-icon-draw-masking,
		.svg-animate-svg-icon-draw-masking {
			width: 64px;
			height: auto;
			margin-bottom: 1rem;
		}

		.hidden {
			display: none;
		}
	}
}