.glow-shadow {
	position: relative;

	& > .glow {
		position: absolute;
		left: 0;
		top: 5px;
		z-index: -1;
		pointer-events: none;
		filter: blur(8px) saturate(1.25) opacity(0.8);

		&.high-glow {
			filter: blur(8px) blur(8px) blur(16px) saturate(1.25) opacity(0.8);
		}

		@media screen and (max-width: 1079px) { // <= mb
			filter: blur(4px) saturate(1.25) opacity(0.8);

			&.high-glow {
				filter: blur(4px) blur(4px) blur(8px) saturate(1.25) opacity(0.8);
			}
		}
	}
}