// margin-rem.scss v1
$breakpoints: (
	xsmb: 0,
	smb: 576px,
	mb: 768px,
	slp: 1080px,
	lp: 1280px,
	sdt: 1367px,
	dt: 1681px,
	ldt: 1921px
);

$margin-values: (
	0: 0,
	1: 0.5rem,
	2: 1rem,
	3: 1.5rem,
	4: 2rem,
	5: 2.5rem,
	6: 3rem,
	7: 3.5rem,
	8: 4rem,
	9: 4.5rem,
	10: 5rem
);

/* Breakpoint: xsmb */
@each $suffix, $size in $margin-values {
	.m-#{$suffix}rem {
		margin: #{$size} !important;
	}

	.mt-#{$suffix}rem {
		margin-top: #{$size} !important;
	}

	.mr-#{$suffix}rem {
		margin-right: #{$size} !important;
	}

	.mb-#{$suffix}rem {
		margin-bottom: #{$size} !important;
	}

	.ml-#{$suffix}rem {
		margin-left: #{$size} !important;
	}

	.my-#{$suffix}rem {
		margin-top: #{$size} !important;
		margin-bottom: #{$size} !important;
	}

	.mx-#{$suffix}rem {
		margin-left: #{$size} !important;
		margin-right: #{$size} !important;
	}
}

@each $breakpoint, $min-width in $breakpoints {
	@if ($min-width != 0) {
		/* Breakpoint: #{$breakpoint} */
		@media (min-width: $min-width) {
			@each $suffix, $size in $margin-values {
				.m-#{$breakpoint}-#{$suffix}rem {
					margin: #{$size} !important;
				}

				.mt-#{$breakpoint}-#{$suffix}rem {
					margin-top: #{$size} !important;
				}

				.mr-#{$breakpoint}-#{$suffix}rem {
					margin-right: #{$size} !important;
				}

				.mb-#{$breakpoint}-#{$suffix}rem {
					margin-bottom: #{$size} !important;
				}

				.ml-#{$breakpoint}-#{$suffix}rem {
					margin-left: #{$size} !important;
				}

				.my-#{$breakpoint}-#{$suffix}rem {
					margin-top: #{$size} !important;
					margin-bottom: #{$size} !important;
				}

				.mx-#{$breakpoint}-#{$suffix}rem {
					margin-right: #{$size} !important;
					margin-left: #{$size} !important;
				}
			}
		}
	}
}