#home {
	position: relative;
	height: auto;
	min-height: calc(100vh + 40px);
	width: 100%;

	@media (min-width: 1080px) { // slp
		min-height: calc(100vh + 60px);
	}

	.main-container, .divider {
		z-index: 4;
	}

	.main-container {
		height: 100vh;

		.home-block {
			height: inherit;
			width: 100%;
			margin: 0 auto;
			padding-left: 32px;

			@media (min-width: 576px) { // smb
				padding-left: 38px;
			}
			@media (min-width: 1080px) { // slp
				padding-left: 39px;
			}
			@media (min-width: 1367px) { // sdt
				padding-left: 40px;
			}
			@media (min-width: 1681px) { // dt
				padding-left: 41px;
			}
			@media (min-width: 1921px) { // ldt
				padding-left: 42px;
			}
		}
	}
}