.cursor-default,
body,
body.cursor-default {
	cursor: url("../others/cursors/phinger/light/left_ptr.svg"),
		url("../others/cursors/phinger/light/left_ptr.png"), auto;
}

.cursor-context-menu,
body.cursor-context-menu {
	cursor: url("../others/cursors/phinger/light/context-menu.svg"),
		url("../others/cursors/phinger/light/context-menu.png"), auto;
}

.cursor-text,
input, input[type="text"] {
	cursor: url("../others/cursors/phinger/light/text.svg"),
		url("../others/cursors/phinger/light/text.png"), auto;
}

.cursor-pointer,
a, button, select, input[type="checkbox"], input[type="radio"] {
	cursor: url("../others/cursors/phinger/light/pointer.svg"),
		url("../others/cursors/phinger/light/pointer.png"), auto;
}