// font-size.scss v1
@mixin fontSize($xsmb: "", $smb: "", $mb: "", $slp: "", $lp: "", $sdt: "", $dt: "", $ldt: "") {
	@if($xsmb != "") { // Extra Small Mobile
		font-size: $xsmb;
	}

	@if($smb != "") { // Small Mobile
		@media all and (min-width: 576px) {
			font-size: $smb;
		}
	}

	@if($mb != "") { // Mobile
		@media all and (min-width: 768px) {
			font-size: $mb;
		}
	}

	@if($slp != "") { // Small Laptop
		@media all and (min-width: 1080px) {
			font-size: $slp;
		}
	}

	@if($lp != "") { // Laptop
		@media all and (min-width: 1280px) {
			font-size: $lp;
		}
	}

	@if($sdt != "") { // Small Desktop
		@media all and (min-width: 1367px) {
			font-size: $sdt;
		}
	}

	@if($dt != "") { // Desktop
		@media all and (min-width: 1681px) {
			font-size: $dt;
		}
	}

	@if($ldt != "") { // Large Desktop
		@media all and (min-width: 1921px) {
			font-size: $ldt;
		}
	}
}