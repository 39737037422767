// padding-rem.scss v1
$breakpoints: (
	xsmb: 0,
	smb: 576px,
	mb: 768px,
	slp: 1080px,
	lp: 1280px,
	sdt: 1367px,
	dt: 1681px,
	ldt: 1921px
);

$padding-values: (
	0: 0,
	1: 0.5rem,
	2: 1rem,
	3: 1.5rem,
	4: 2rem,
	5: 2.5rem,
	6: 3rem,
	7: 3.5rem,
	8: 4rem,
	9: 4.5rem,
	10: 5rem
);

/* Breakpoint: xsmb */
@each $suffix, $size in $padding-values {
	.p-#{$suffix}rem {
		padding: #{$size} !important;
	}

	.pt-#{$suffix}rem {
		padding-top: #{$size} !important;
	}

	.pr-#{$suffix}rem {
		padding-right: #{$size} !important;
	}

	.pb-#{$suffix}rem {
		padding-bottom: #{$size} !important;
	}

	.pl-#{$suffix}rem {
		padding-left: #{$size} !important;
	}

	.py-#{$suffix}rem {
		padding-top: #{$size} !important;
		padding-bottom: #{$size} !important;
	}

	.px-#{$suffix}rem {
		padding-left: #{$size} !important;
		padding-right: #{$size} !important;
	}
}

@each $breakpoint, $min-width in $breakpoints {
	@if ($min-width != 0) {
		/* Breakpoint: #{$breakpoint} */
		@media (min-width: $min-width) {
			@each $suffix, $size in $padding-values {
				.p-#{$breakpoint}-#{$suffix}rem {
					padding: #{$size} !important;
				}

				.pt-#{$breakpoint}-#{$suffix}rem {
					padding-top: #{$size} !important;
				}


				.pr-#{$breakpoint}-#{$suffix}rem {
					padding-right: #{$size} !important;
				}

				.pb-#{$breakpoint}-#{$suffix}rem {
					padding-bottom: #{$size} !important;
				}

				.pl-#{$breakpoint}-#{$suffix}rem {
					padding-left: #{$size} !important;
				}

				.py-#{$breakpoint}-#{$suffix}rem {
					padding-top: #{$size} !important;
					padding-bottom: #{$size} !important;
				}

				.px-#{$breakpoint}-#{$suffix}rem {
					padding-left: #{$size} !important;
					padding-right: #{$size} !important;
				}
			}
		}
	}
}