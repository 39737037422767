@import "../../css/variables.scss";
@import "../../css/mixins-and-functions.scss";

#portfolio {
	min-height: calc(100vh + 60px);
	background-image: url("../../imgs/portfolio/portfolio-bg.jpg");
	background-position: center 100%;
	background-size: cover;

	&>.main-container {
		position: relative;
		z-index: 3;

		padding-top: calc(40px * 1.5);
		padding-bottom: calc(40px * 1.5);

		@media (min-width: 1367px) {
			// sdt
			padding-top: calc(60px * 1.5);
			padding-bottom: calc(60px * 1.5);
		}
	}

	@include forEach($websites) {
		// $length, $index, $value
		$website: $value;
		$websiteColor: nth($websiteColors, $index);

		.website-#{$website} {
			.website-color {
				color: $websiteColor;
				transition: all linear 0.4s;
			}

			.website-bg {
				background-color: $websiteColor;
				transition: all linear 0.4s;
			}

			.os-scrollbar .os-scrollbar-track {

				&:hover,
				&.hover {
					background-color: rgba($websiteColor, 0.25);

					.os-scrollbar-handle {
						background-color: $websiteColor;
					}
				}

				.os-scrollbar-handle {
					background-color: rgba($websiteColor, 0.5);
				}
			}
		}
	}
}