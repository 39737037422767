#about-me-carousel {
	height: 100%;

	@media screen and (max-width: 1079px) { // <= mb
		height: auto;
		width: calc(100% - 25px);
	}

	.slick-slider,
	.slick-list,
	.slick-track,
	.slick-slide,
	.slick-slide > div {
		height: 100%;
	}

	@media screen and (max-width: 1079px) { // <= mb
		.slick-track {
			height: auto !important;

			.slick-slide {
				margin-bottom: 1.5rem;
			}
		}
	}
}

#about-me-carousel .adaptative-padding {
	@media screen and (min-width: 1080px) { // slp
		padding-left: calc(40px / 2);
	}

	@media screen and (min-width: 1280px) { // lp
		padding-left: calc(45px / 2);
	}

	@media screen and (min-width: 1367px) { // sdt
		padding-left: calc(50px / 2);
	}

	@media screen and (min-width: 1681px) { // dt
		padding-left: calc(55px / 2);
	}

	@media screen and (min-width: 1921px) { // ldt
		padding-left: 60px;
	}
}