#about-me {
	z-index: 3;
	background-color: #fff;

	.main-container {
		@media screen and (min-width: 1080px) {
			// >= slp
			min-height: 500px;
		}

		.about-me-padding {
			@media screen and (min-width: 1080px) {
				// >= slp
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}
	}

	.block-me-img {
		position: relative;
		text-align: center;

		@media screen and (min-width: 1080px) {
			// sm
			text-align: right;
		}

		.me-img {
			border-radius: 36px;
			min-height: 500px;
			max-height: calc(100vh - 10rem);
			margin-right: calc(20px + 16px); // 20px = icon size / 2, 16px = padding
		}
	}
}

#about-me .adaptative-padding {
	@media screen and (min-width: 1080px) {
		// slp
		padding-right: calc(40px / 2);
	}

	@media screen and (min-width: 1280px) {
		// lp
		padding-right: calc(45px / 2);
	}

	@media screen and (min-width: 1367px) {
		// sdt
		padding-right: calc(50px / 2);
	}

	@media screen and (min-width: 1681px) {
		// dt
		padding-right: calc(55px / 2);
	}

	@media screen and (min-width: 1921px) {
		// ldt
		padding-right: 60px;
	}
}