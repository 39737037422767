#UnderConstruction {
	z-index: 9;
	position: fixed;
	left: 0;
	bottom: 0;

	height: 2.5rem;
	width: 100%;
	transition: all linear 0.4s;

	&.drop {
		bottom: -2.5rem;
	}

	p {
		margin: 0;

		color: #fff;
		font-size: 1.15rem;
		font-weight: 700;
		text-align: center;
	}

	.background-color {
		z-index: -1;
		position: absolute;
		opacity: 0.5;

		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}