// mobile
$imgSizeMb: 55%;
$centerMb: calc(50% - $imgSizeMb / 2);
$imgScaleDiffMb: calc($imgSizeMb / 2 / 10);

// laptop
$imgSizeLp: 50%;
$centerLp: calc(50% - $imgSizeLp / 2);
$imgScaleDiffLp: calc($imgSizeLp / 2 / 10);

// desktop
$imgSizeDt: 37.5%;
$centerDt: calc(50% - $imgSizeDt / 2);
$imgScaleDiffDt: calc($imgSizeDt / 2 / 10);

#portfolio-carousel {
	position: relative;
	height: 100vh;

	.websites {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		margin-top: 5vh;
		height: 90%;

		.website {
			width: $imgSizeMb;

			position: absolute;
			top: 0;
			height: auto;
			max-height: 100%;
			border-radius: 20px;
			transition: all linear 0.4s;

			&.website-0 {
				transform: scale(0.7);
				filter: blur(5px) saturate(0);
				opacity: 0.4;
				z-index: 1;
			}

			&.website-1 {
				transform: scale(0.7);
				filter: blur(5px) saturate(0);
				opacity: 0.4;
				z-index: 1;
			}

			&.website-2 {
				transform: scale(0.8);
				filter: blur(2px) saturate(0);
				opacity: 1;
				z-index: 3;
			}

			&.website-3 {
				// center
				transform: scale(1);
				filter: blur(0) saturate(1);
				opacity: 1;
				z-index: 4;

				&.website-glow {
					opacity: 1;
					filter: blur(10px) saturate(1.1);
					z-index: 3;
				}
			}

			&.website-4 {
				transform: scale(0.8);
				filter: blur(2px) saturate(0);
				opacity: 0.8;
				z-index: 3;
			}

			&.website-5 {
				transform: scale(0.7);
				filter: blur(5px) saturate(0);
				opacity: 0.4;
				z-index: 1;
			}

			&.website-6 {
				transform: scale(0.7);
				filter: blur(5px) saturate(0);
				opacity: 0.4;
				z-index: 1;
			}

			&.website-glow {
				opacity: 0;
				z-index: 0;
			}

			>[data-overlayscrollbars] {
				max-height: 100vh;
			}

			// mobile
			&.website-0 {
				left: calc($centerMb);
			}

			&.website-1 {
				left: calc($centerMb);
			}

			&.website-2 {
				left: calc(0%);
			}

			&.website-3 {
				// center
				left: calc($centerMb);
			}

			&.website-4 {
				left: calc(100% - $imgSizeMb);
			}

			&.website-5 {
				left: calc($centerMb);
			}

			&.website-6 {
				left: calc($centerMb);
			}

			// laptop
			@media all and (min-width: 1080px) {
				// slp
				width: $imgSizeLp;

				&.website-0 {
					left: calc($centerLp);
				}

				&.website-1 {
					left: calc(0%);
				}

				&.website-2 {
					left: calc(33.33% - $imgSizeLp / 2);
				}

				&.website-3 {
					// center
					left: calc($centerLp);
				}

				&.website-4 {
					left: calc(66.66% - $imgSizeLp / 2);
				}

				&.website-5 {
					left: calc(100% - $imgSizeLp);
				}

				&.website-6 {
					left: calc($centerLp);
				}
			}

			// desktop
			@media all and (min-width: 1367px) {
				// sdt
				width: $imgSizeDt;

				&.website-0 {
					left: calc($centerDt);
				}

				&.website-1 {
					left: calc(0%);
				}

				&.website-2 {
					left: calc(30% - $imgSizeDt / 2);
				}

				&.website-3 {
					// center
					left: calc($centerDt);
				}

				&.website-4 {
					left: calc(70% - $imgSizeDt / 2);
				}

				&.website-5 {
					left: calc(100% - $imgSizeDt);
				}

				&.website-6 {
					left: calc($centerDt);
				}
			}
		}
	}
}

#portfolio-carousel {
	.website {
		overflow: hidden;

		.os-scrollbar {
			display: none;
		}

		&.website-3 {

			// center
			.os-scrollbar {
				display: block;
			}
		}
	}
}