/* animate-svg-icon-draw-masking.css v0.2 beta */
.block-animate-svg-icon-draw-masking {
	position: relative;
	height: fit-content;
	width: fit-content;
	-webkit-mask-size: 100%;
	mask-size: 100%;
}

.block-animate-svg-icon-draw-masking svg.svg-animate-svg-icon-draw-masking {
	position: absolute;
	left: 0;
	top: 0;
	height: inherit;
	width: inherit;
}

.block-animate-svg-icon-draw-masking img.animate-svg-icon-draw-masking {
	opacity: 0;
}