@import "../../css/mixins-and-functions.scss";
@import "../../css/variables.scss";

#menu {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 98;
	background-clip: padding-box;
	padding: 1rem 1.5rem;

	.menu-container {
		overflow: hidden;
		position: relative;
		background-color: rgba(255, 255, 255, 0.85);
		border-radius: 99px;
		padding: 7px;

		.btn-menu {
			position: relative;
			z-index: 2;
			height: 30px;
			padding: 0rem 0.75rem;

			@media (min-width: 1367px) { // sdt
				padding: 0rem 1rem;
				height: 40px;
			}

			// font
			letter-spacing: 2px;
			line-height: 20px;
			text-transform: uppercase;
			color: #282828;

			transition: all linear 0.4s;

			&.active {
				color: #fff;
				font-weight: 600;
				transition: all linear 0.4s;
			}
		}

		.btn-glow {
			margin-top: 7px;
			position: absolute;
			top: 0;
			height: 30px;
			z-index: 1;
			transition: all linear 0.4s;

			@media (min-width: 1367px) { // sdt
				height: 40px;
			}
		}

		.btn-menu-bg {
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			height: 30px;
			width: 100%;
			border-radius: 99px;
			transition: all linear 0.4s;

			@media (min-width: 1367px) { // sdt
				height: 40px;
			}
		}
	}
}

@include forEach($rainbowOptions) {
	$incrementOfLight: get($item, incrementOfLight);
	$gradientColor1: get($item, gradientColor1);
	$gradientColor2: get($item, gradientColor2);
	$gradientPosition1: get($item, gradientPosition1);
	$gradientPosition2: get($item, gradientPosition2);
	$gradientColor1_hover: lighten($gradientColor1, $incrementOfLight);
	$gradientColor2_hover: lighten($gradientColor2, $incrementOfLight);

	.rainbow-color-#{$index - 1} {
		.btn-menu-bg {
			background-image: linear-gradient(
				to right,
				$gradientColor1 $gradientPosition1,
				$gradientColor2 $gradientPosition2
			);

			&:hover {
				background-image: linear-gradient(
					to right,
					$gradientColor1_hover $gradientPosition1,
					$gradientColor2_hover $gradientPosition2
				);
			}
		}
	}
}