.about-me-slider {
	.block-text {
		padding-right: 25px;

		@media screen and (min-width: 1080px) { // >= slp
			max-height: 300px;
		}
	}

	.btn-align-position {
		position: relative;
		left: -10px;
		display: inline-block;
	}
}