@import "./tailwind.scss";
@import url("./complements/tailwind-enhanced.scss");

// tailwind enhanced
* {
	outline: none;
}

// libs
@import "./css/overlayscrollbars.scss";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// complements
@import "./complements/margin-rem.scss";
@import "./complements/padding-rem.scss";
@import url("./complements/animate-svg-icon-draw-masking.css");

// fonts
@import "./others/fonts/Figtree/stylesheet.css";
@import "./others/fonts/Source-Sans-Pro/stylesheet.css";

@import "./css/cursors.scss";
@import "./css/fonts.scss";

// libs
@import "tippy.js/dist/tippy.css";
@import "devicon/devicon.min.css";

// tippy.js
.tippy-content,
.tippy-content div {
	color: #fff;
}

// /tippy.js

// sweetAlert2
body {
	padding-right: 0 !important;
}

.swal2-container>div {
	margin-bottom: 15vh;
}

div:where(.swal2-container) div:where(.swal2-popup) {
	border-radius: 0.75rem !important;
}

// title
div:where(.swal2-container) h2:where(.swal2-title) {
	padding-top: 1rem !important;
	line-height: 1.75rem !important;
}

// text
div:where(.swal2-container) .swal2-html-container {
	font-size: 1.35rem !important;
	padding-top: 0.5rem !important;
}

// background
div:where(.swal2-container).swal2-backdrop-show {
	background-color: rgba(0, 0, 0, 0.7) !important;
}

// button
div:where(.swal2-container) div:where(.swal2-actions) {
	margin-top: 0.5rem !important;
	margin-bottom: 1.35rem !important;
}

// /sweetAlert2

.grid-center {
	display: grid;
	place-items: center;
	align-content: center;
}

.img-fluid {
	height: auto;
	max-width: 100%;
}

section {
	position: relative;
	height: auto;
	min-height: 100vh;
}

@for $i from 1 through 12 {
	.cols-max-content-#{$i} {
		grid-template-columns: repeat(#{$i}, max-content);
	}
}

// slick enhanced
.slick-slider {
	user-select: auto;
	-webkit-user-select: auto;

	.slider {
		height: 100%;

		&.slider-items-center {
			align-items: center;
			display: grid !important;
			height: 100%;
		}
	}
}

// globals
button {
	user-select: none;
}

.bg-gray-important {
	$gray1: #777;
	$gray2: #444;
	background-image: linear-gradient(to right, $gray1 0%, $gray2 80%);

	&:hover {
		background-image: linear-gradient(to right, lighten($gray1, 7.5%) 0%, lighten($gray2, 7.5%) 80%);
	}

	.btn-background-color {
		display: none !important;
	}
}

.flip-h {
	transform: scale(-1, 1);
	transition-duration: 0s;
}

.flip-v {
	transform: scale(1, -1);
	transition-duration: 0s;
}

.flip-h.flip-v {
	transform: scale(-1, -1);
	transition-duration: 0s;
}

.hr {
	width: 25px;
	height: 4px;
	margin-top: 0.33rem;
	margin-bottom: 0.66rem;
}

.color-white {
	color: #fff;
}

.bg-white {
	background-color: #fff;
}

.color-carbon {
	color: #282828;
}

.bg-carbon {
	background-color: #282828;
}

.color-black {
	color: #000;
}

.bg-black {
	background-color: #000;
}

.h-smart {
	height: auto;
	min-height: inherit;
	max-height: inherit;
}

.main-container {
	margin: 0 auto;
	max-width: 2560px;
	position: relative;
	width: 100%;

	// xsmb
	padding-left: 20px;
	padding-right: 20px;

	@media screen and (min-width: 576px) {
		// smb
		padding-left: 25px;
		padding-right: 25px;
	}

	@media screen and (min-width: 768px) {
		// mb
		padding-left: 30px;
		padding-right: 30px;
	}

	@media screen and (min-width: 1080px) {
		// slp
		padding-left: 35px;
		padding-right: 35px;
	}

	@media screen and (min-width: 1280px) {
		// lp
		padding-left: 40px;
		padding-right: 40px;
	}

	@media screen and (min-width: 1367px) {
		// sdt
		padding-left: 45px;
		padding-right: 45px;
	}

	@media screen and (min-width: 1681px) {
		// dt
		padding-left: 50px;
		padding-right: 50px;
	}

	@media screen and (min-width: 1921px) {
		// ldt
		padding-left: 55px;
		padding-right: 55px;
	}
}

.smart-gap {
	&:nth-child(1) {
		@media screen and (min-width: 1080px) {
			// slp
			padding-right: calc(35px / 2);
		}

		@media screen and (min-width: 1280px) {
			// lp
			padding-right: calc(40px / 2);
		}

		@media screen and (min-width: 1367px) {
			// sdt
			padding-right: calc(45px / 2);
		}

		@media screen and (min-width: 1681px) {
			// dt
			padding-right: calc(50px / 2);
		}

		@media screen and (min-width: 1921px) {
			// ldt
			padding-right: calc(55px / 2);
		}
	}

	&:nth-child(2) {
		@media screen and (min-width: 1080px) {
			// slp
			padding-left: calc(35px / 2);
		}

		@media screen and (min-width: 1280px) {
			// lp
			padding-left: calc(40px / 2);
		}

		@media screen and (min-width: 1367px) {
			// sdt
			padding-left: calc(45px / 2);
		}

		@media screen and (min-width: 1681px) {
			// dt
			padding-left: calc(50px / 2);
		}

		@media screen and (min-width: 1921px) {
			// ldt
			padding-left: calc(55px / 2);
		}
	}
}