.divider {
	position: absolute;
	top: 0;
	left: 0;
	transform: rotate(180deg);

	width: 100%;

	mask-repeat: repeat;
	-webkit-mask-repeat: repeat;

	background-color: #fff;
	mask: url("../../imgs/divider.png");
	-webkit-mask: url("../../imgs/divider.png");

	height: 40px;
	mask-size: auto 40px;
	-webkit-mask-size: auto 40px;

	@media (min-width: 1367px) { // sdt
		height: 60px;
		mask-size: auto 60px;
		-webkit-mask-size: auto 60px;
	}

	&.divider-bottom {
		bottom: 0;
		top: initial;
		transform: rotate(0deg);
	}
}