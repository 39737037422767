#home-bg, .img-bg, .gradient-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: inherit;

	height: auto;
	min-height: calc(100vh + 150px);

	@media (min-width: 1080px) { // slp
		min-height: calc(100vh + 125px);
	}
	@media (min-width: 1367px) { // sdt
		min-height: calc(100vh + 150px);
	}
}

#home-bg {
	// transform: scale(-1, 1);

	.img-bg {
		background-image: url("../../imgs/home/home-bg.jpg");
		background-position: center 100%;
		background-size: cover;

		@media screen and (max-width: 1920px) { // <= dt
			background-image: url("../../imgs/home/home-bg-1920px.jpg");
		}
	}
	.gradient-bg {
		z-index: 3;
		background: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0.5) 0%,
			rgba(0, 0, 0, 0.5) 40%,
			rgba(0, 0, 0, 0) 100%
		);
	}
}