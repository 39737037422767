@import "../../complements/font-size.scss";

#contact {
	min-height: calc(100vh + 60px);
	padding-top: 60px;
	background-image: url("../../imgs/contact/contact-bg-1920px.jpg");
	background-position: center 100%;
	background-size: cover;

	@media (min-width: 1080px) {
		// slp
		overflow: hidden;
	}

	@media screen and (min-width: 1921px) {
		// >= dt
		background-image: url("../../imgs/contact/contact-bg.jpg");
	}

	.main-container {
		min-height: 100vh;
		z-index: 4;

		padding-top: calc(40px * 1.5);

		@media (min-width: 1367px) {
			// sdt
			padding-top: calc(60px * 1.5);
		}


		.box-text {
			text-align: center;

			h1 {
				@include fontSize(3rem, 4rem, 4.5rem, 4.75rem, 5.5rem, 6rem, 6.5rem, 7rem);
				text-shadow:
					0px 0px 10px rgb(0, 0, 0, 0.25),
					0px 0px 20px rgb(0, 0, 0, 0.25),
					0px 0px 30px rgb(0, 0, 0, 0.25),
					0px 0px 40px rgb(0, 0, 0, 0.25),
					0px 0px 50px rgb(0, 0, 0, 0.25);

				&.last {
					margin-bottom: 5vh;

					@media (min-width: 1080px) {
						// slp
						margin-bottom: 15vh;
					}
				}
			}
		}

		.box-neon {
			position: relative;
			background-color: rgba(0, 0, 0, 0.5);
			box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.5);
			padding: 1rem 2rem;
			border-radius: 0.75rem;

			@media screen and (min-width: 1080px) {
				// slp
				position: absolute;
				width: fit-content;
			}

			// custom responsive
			transform: scale(0.8);

			@media all and (min-width: 1150px) {
				transform: scale(0.9);
			}

			@media all and (min-width: 1280px) {
				transform: scale(1);
			}

			@media (min-width: 1080px) {
				&.box-text {
					left: 0;
					top: 0
				}
			}

			@media (min-width: 1080px) {
				&.box-form {
					left: calc(10px);
					top: calc(8%);
				}
			}

			@media (min-width: 1600px) {
				&.box-form {
					left: calc(2.5%)
				}
			}

			@media (min-width: 1700px) {
				&.box-form {
					left: calc(4%)
				}
			}

			@media (min-width: 1800px) {
				&.box-form {
					left: calc(6%)
				}
			}

			@media (min-width: 1080px) {
				&.box-availability {
					right: calc(0%);
					top: calc(17%)
				}
			}

			@media (min-width: 1100px) {
				&.box-availability {
					right: calc(4%)
				}
			}

			@media (min-width: 1200px) {
				&.box-availability {
					right: calc(6%)
				}
			}

			@media (min-width: 1080px) {
				&.box-contacts {
					left: calc(25%);
					top: calc(75%)
				}
			}

			@media (min-width: 1150px) {
				&.box-contacts {
					left: calc(30%);
					top: calc(75%)
				}
			}

			@media (min-width: 1080px) {
				&.box-social-media-plataforms {
					right: calc(35px);
					top: calc(50%)
				}
			}

			@media (min-width: 1300px) {
				&.box-social-media-plataforms {
					right: calc(2.5%)
				}
			}

			@media (min-width: 1800px) {
				&.box-social-media-plataforms {
					right: calc(4%)
				}
			}

			@media (min-width: 1900px) {
				&.box-social-media-plataforms {
					right: calc(6%)
				}
			}

			// /custom responsive

			.hr {
				margin-left: auto;
				margin-right: auto;
			}

			p {
				margin-bottom: 0;
				line-height: 125%;
			}

			h5,
			p {
				color: #fff;
				text-align: center;

				span {
					text-shadow:
						0px 0px 3px rgb(0, 0, 0, 0.75),
						0px 0px 6px rgb(0, 0, 0, 0.75),
						0px 0px 9px rgb(0, 0, 0, 0.75),
						0px 0px 12px rgb(0, 0, 0, 0.75);
				}
			}

			&.box-form {

				input,
				textarea {
					width: 100%;
					margin-bottom: 0.75rem;
					padding: 0.25rem 0.75rem;
					border-radius: 0.75rem;
				}

				textarea {
					width: 100%;
				}
			}

			&.box-availability {
				.dot {
					$dotHeight: 16px;

					display: inline-block;
					position: relative;
					top: 2px;
					width: $dotHeight;
					height: $dotHeight;
					margin-left: 10px;
					margin-right: 5px;
					border-radius: 100%;
					animation: blink 3s linear infinite;

					&:after {
						content: "";
						position: absolute;
						top: calc($dotHeight / 4);
						left: calc($dotHeight / 4);
						width: 50%;
						height: 50%;
						border-radius: 100%;
					}

					background-color: rgba(230, 0, 0, 0.5);

					&:after {
						background-color: rgba(230, 0, 0, 1);
					}

					&.active {
						background-color: rgba(0, 230, 0, 0.5);

						&:after {
							background-color: rgba(0, 230, 0, 1);
						}
					}
				}

				@keyframes blink {
					0% {
						opacity: 1;
					}

					50% {
						opacity: 0.5;
					}

					100% {
						opacity: 1;
					}
				}
			}

			// &.box-contacts {
			// }

			&.box-social-media-plataforms {
				.block-icons {
					position: relative;
					margin: 0 auto;
					max-width: 500px;

					@media (min-width: 1080px) {
						// slp
						max-width: 250px;
					}

					.icon-grid {
						padding-bottom: 0.25rem;

						@media (min-width: 1080px) {

							// slp
							&:nth-child(1),
							&:nth-child(2),
							&:nth-child(3) {
								padding-bottom: 0.85rem;
							}

							&:nth-child(4),
							&:nth-child(5),
							&:nth-child(6) {
								padding-bottom: 0;
							}
						}

						.img-icon {
							width: 48px;
							height: auto;
						}
					}
				}
			}
		}
	}
}